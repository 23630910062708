import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { StorageService } from './encrypt-storage.service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  localStorage: Storage;

  changes$ = new Subject();

  constructor(private storageService: StorageService) {
    this.localStorage = window.localStorage;
  }

  get(key: string): any {
      return JSON.parse(this.storageService.secureStorage.getItem(key));
  }

  set(key: string, value: any): boolean {
      this.storageService.secureStorage.setItem(key, JSON.stringify(value));
      this.changes$.next({
        type: 'set',
        key,
        value,
      });
      return true;
  }

  clearToken() {
    return this.storageService.secureStorage.clear();
  }

  removeItem(key) {
    return this.localStorage.removeItem(key);
  }
}
