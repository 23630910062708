import { I18NextModule, I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { format } from 'date-fns'
import { TenantService } from '../shared/services/tenant/tenant.service';
const setLang = TenantService.prototype.getLang;
/* istanbul ignore next */
const options = {
  whitelist: setLang,  // no need to block
  fallbackLng: setLang[0],
  ns: ['translation'],
  defaultNS: 'translation',
  debug: false,
  returnEmptyString: false,
  backend: {
    loadPath: '/assets/locales/{{lng}}/{{ns}}.json'
  },
  interpolation: {
    format: function (value, valueFormat, lng) {
      if (valueFormat === 'uppercase') {
        return value.toUpperCase();
      }
      if (value instanceof Date) {
        return format(value, valueFormat, {
          locale: lng
        });
      }
      return value;
    }
  }
};

export function appInit(i18next: ITranslationService) {
  return () => i18next
    .use(Backend)
    .use(LanguageDetector as any)
    .init(options);
}

export function localeIdFactory(i18next: ITranslationService) {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory
  }];

export const I18N_IMPORTS = [
  I18NextModule.forRoot(),
];

export const I18N_MODULE = [
  I18NextModule
];


