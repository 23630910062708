import { Component, OnInit, Input, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { ApkDropdownOption, ApkNotificationService } from "@ak4a/components";
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { GLOBALS, Global } from '../../../core/global';
import { ContactSupportAdminService } from '../../../main/contact-support-admin/contact-support-admin.service';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.scss'],
  providers: [DatePipe]
})
export class ContactSupportComponent implements OnInit, OnDestroy {
  data: any;
  index: any;
  transformDate: string;
  subjectAdd: any;
  transformTime: string;
  editdisplaytime: string;
  pipe: any;
  captchaErr = false;
  captchaToken: any;

  constructor(@Inject(I18NEXT_SERVICE) private readonly i18NextService: ITranslationService, @Inject(GLOBALS) public g: Global,
    private readonly formBuilder: FormBuilder, private readonly contactService: ContactSupportAdminService,
    public readonly datepipe: DatePipe, private readonly notificationService: ApkNotificationService) {

  }
  @Input() modalDisplay = false;
  @Output() modalDisplayChange = new EventEmitter<any>();

  categories: Array<ApkDropdownOption>;
  category: any;
  subject: string;
  comment: string;
  categoryErr = false;
  subjectErr = false;
  commentsErr = false;
  sendForm: FormGroup;
  ngUnsubscribe = new Subject();


  ngOnInit(): void {
    const categoriesLength = 8;
    let tmpCategories = [];
    for (let i = 1; i <= categoriesLength; i++) {
      tmpCategories = [...tmpCategories, {
        value: this.i18NextService.t(`contact_support.categories.option${i}`),
        label: this.i18NextService.t(`contact_support.categories.option${i}`)
      }]
    }
    this.categories = tmpCategories;
    this.sendForm = this.formBuilder.group({
      category: [this.categories[0].value, { validators: [Validators.required,], }],
      subject: ['', { validators: [Validators.required,], }],
      comment: ['', { validators: [Validators.required,], }],
      captcha: ['', { validators: [Validators.required,], }],
    });
  }


  onSend(form) {
    this.handleErrors();
    this.pipe = new DatePipe('en-US');
      const contactSupportDetails = {
        category: this.sendForm.get('category').value,
        creator: this.g.user.unique_name,
        subject: this.sendForm.controls.subject.value,
        comment: this.sendForm.controls.comment.value,
        status: "New",
        captchaToken: this.captchaToken,
        action: 'onsend'
      }
    if (!(this.subjectErr) && !(this.categoryErr) && !(this.commentsErr) && !(this.captchaErr)) {
      this.contactService.postContactSupportDetails(contactSupportDetails).subscribe((res) => {
        this.notificationService.success(this.i18NextService.t('contactSupportAdmin.message.saveSuccess'), 4000, false);
        this.closeModal(form);
      },
        (err) => {
          this.notificationService.error(this.i18NextService.t('financial_info.leasingCar.message.msgFail'), 4000, false);
          this.closeModal(form);
        }
      )
    }
  }

  handleErrors() {
    if (this.sendForm.get('subject').value === '' || this.sendForm.get('subject').value === null || this.sendForm.get('subject').value.trim() === '') {
      this.subjectErr = true;
    } else {
      this.subjectErr = false;
    }
    if (this.sendForm.get('category').value === 'Select' || this.sendForm.get('category').value === 'Selecciona') {
      this.categoryErr = true;
    } else {
      this.categoryErr = false;
    }
    if (this.sendForm.get('comment').value === '' || this.sendForm.get('comment').value === null || this.sendForm.get('comment').value.trim() === '') {
      this.commentsErr = true;
    } else {
      this.commentsErr = false;
    }
    this.captchaErr = this.sendForm.get('captcha').invalid;
  }

  closeModal(form) {
    form.reset();
    this.sendForm.reset({
      category: this.categories[0].value,
    });
    this.categoryErr = false;
    this.subjectErr = false;
    this.commentsErr = false;
    this.modalDisplay = false;
    this.captchaErr = false;
    this.modalDisplayChange.emit(false);
  }

  cancelModal(form) {
    form.reset();
    this.sendForm.reset({
      category: this.categories[0].value,
    });
    this.notificationService.warning(this.i18NextService.t('financial_info.leasingCar.message.actionCancelled'), 4000, false);
    this.categoryErr = false;
    this.subjectErr = false;
    this.commentsErr = false;
    this.modalDisplay = false;
    this.captchaErr = false;
    this.modalDisplayChange.emit(false);
  }

  getToken(token: string | null) {
    this.captchaToken = token;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
