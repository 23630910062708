import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionManagementService } from './session-mangement.service';

@Directive({
  selector: '[appActivityWatcher]'
})
export class ActivityWatcherDirective implements OnInit {

  @Input() resetActivityOnMouseClick = false;
  @Input() resetActivityOnMouseMove = false;
  @Input() inActivityTimeInMilliSeconds = 900000;

  constructor(private readonly sessionManagementService: SessionManagementService,
    private readonly router: Router) { }

  @HostListener('document:click', []) handleMouseClick() {
    if (this.resetActivityOnMouseClick) {
        this.resetActivityTrackingTimers();
      }
  }

  @HostListener('document:mousemove', []) handleMouseMove() {
    if (this.resetActivityOnMouseMove) {
      this.resetActivityTrackingTimers();
    }
  }

  ngOnInit() {
    this.sessionManagementService.isSessionValid.subscribe(val => {
      if (val) {
        this.logout();
      }
    });
    this.sessionManagementService.activateTimers(this.inActivityTimeInMilliSeconds);
  }
  resetActivityTrackingTimers() {
    this.sessionManagementService.resetTimers(this.inActivityTimeInMilliSeconds);
  }

  logout() {
    this.router.navigate(['/logout'], {queryParams: {expired: true}});
  }
}

