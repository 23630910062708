import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityWatcherDirective } from './activity-watcher.directive';



@NgModule({
  declarations: [ActivityWatcherDirective],
  imports: [
    CommonModule
  ],
  exports: [ActivityWatcherDirective]
})
export class SessionManagementModule { }
