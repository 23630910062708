import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthenticationService} from '@quicksuite/commons-auth-plugin';

import { Router } from '@angular/router';

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.css']
})

export class LoginLayoutComponent implements OnInit {

  public form: FormGroup;

  constructor(private readonly fb: FormBuilder, private readonly authService: AuthenticationService, private readonly router: Router) {
    this.form = this.fb.group({
      guid: [''],
      password: [''],
      remember: [''],
    });
  }

  ngOnInit() {
    this.authService.Logout();
    setTimeout(() => {
      window.location.href = '/';
      }, 4000);
  }


  login() {
    this.router.navigate(['/']);
  }

}
