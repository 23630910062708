import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from "../interceptor/http.client";

@Injectable({
  providedIn: 'root'
})

export class ConfigurationService {

  constructor(
    private readonly httpService: ApplicationHttpClient,
  ) { }


  getTenantConfiguration() {
    return this.httpService.get('mxrpp', '/config/upaslinks');
  }

  getAskQuestionEmail() {
    return this.httpService.get('mxrpp', '/config/partnerConnectivityMail');
  }

  getCountries() {
    return this.httpService.get('mxrpp', '/config/getCountries');
  }

  public getLinkName() {
    return this.httpService.get('mxrpp', `/financialInfo/getTaxationLinkNames`)
  }

  public getDynamicDocType() {
    return this.httpService.get('mxrpp', `/financialInfo/documentTags`)
  }
  
}
