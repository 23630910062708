import { IBskyTableColumn } from "../bsky-table/bsky-table.model";
import { ISelectionSearchOptions } from "../selection-search/selection-search.component";

const APP_NAME = 'Partner Documents';

const PARTNER = 'Partner';

const ITEMS_PER_PAGE = 30;

const sortDate = (a: string | Date, b: string | Date, isAsc: boolean) => {
	const a_date = typeof(a) === 'string' ? new Date(a) : a;
	const b_date = typeof(b) === 'string' ? new Date(b) : b;

	return (a_date.getTime() < b_date.getTime() ? -1 : 1) * (isAsc ? 1 : -1);
}

const documentsColumns: IBskyTableColumn[] = [
	{ value: 'name', name: 'Name', sortable: true },
	{ value: 'year', name: 'Year', sortable: true },
	{ value: 'created', name: 'Created', sortable: true, sort: sortDate }
];

const searchByOptions: ISelectionSearchOptions[] = [
	{ label: 'Name', value: 'name' },
	{ label: 'Year', value: 'year' },
	{ label: 'Created', value: 'created' }
]

export { APP_NAME, PARTNER, ITEMS_PER_PAGE, documentsColumns, searchByOptions }