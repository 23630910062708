<div class="page-container">
  <div class="form-area position-relative text-center m-auto p-5 overflow-hidden">
    <div class="form-content">
      <div class="mb-5">
        <img src="../../../assets/images/pwc_medium_logo.png" alt="PwC Logo" class="logo" />
      </div>
      <p class="logout-banner-text my-4">
        {{
        expiredView
        ? "You have logged out due to session expiry"
        : "You have successfully logged out"
        }}
      </p>
      <p *ngIf="showLoginLink; else closeMsg" class="logout-banner-text">
        {{ expiredView ? "To login again - " : "To return home - "
        }}<a [routerLink]='' (click)="login()">click here</a>
      </p>
      <ng-template #closeMsg>
        <p class="logout-banner-text">Close this tab</p>
      </ng-template>
    </div>
  </div>
</div>

<apk-footer class="footer fixed-bottom apk-light-footer" style="margin-bottom:-20px" [dark]="false">
  <div [ngClass]="{'left-align': leftAlignFooter()}">
  <span [innerHTML]="footerContent"></span>
  <span [innerHTML]="CommonFooterContent"></span>
</div>
</apk-footer>