import { Injectable } from '@angular/core';
import { HttpHeaders } from "@angular/common/http";
import { HOSTNAME_TENANT_MAP, PARTNER_DOCS_HOSTNAMES, Languages } from '../../constants/constants';


@Injectable({
  providedIn: 'root'
})
export class TenantService {
  storedTenentValue;
  getTenantForHostname(hostname: string) {
    const tenantFlag = HOSTNAME_TENANT_MAP.filter(data => data.hostnames.includes(hostname));
    this.storedTenentValue = tenantFlag[0]?.tenantId;
    return tenantFlag[0];
  }

  getTenant() {
    return this.storedTenentValue || this.getTenantForHostname(location.hostname)?.tenantId;
  }

  addTenantToHeaders(headers: HttpHeaders): HttpHeaders {
    return headers.append("X-Tenant-ID", this.getTenant());
  }

  isPartnerDocs(): boolean {
    return PARTNER_DOCS_HOSTNAMES.includes(location.hostname);
  }

  get getLang() {
    return this.isPartnerDocs() ? [Languages.en] : this.getTenantForHostname(location.hostname)?.lang;
  }

}
