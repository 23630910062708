import { Injectable } from "@angular/core";
import { ApplicationHttpClient } from "../interceptor/http.client";
import { catchError } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class RbacService {
  constructor(
    private readonly httpService: ApplicationHttpClient,
    public readonly http: HttpClient
  ) { }

  getLoginUserDetails() {
    return this.httpService
      .getWithoutMethodName("userrolepermissions", "")
      .pipe(catchError(this.httpService.handleError("get")));
  }

  public getAllRoles() {
    return this.httpService
    .getWithoutMethodName("/roles", "")
    .pipe(catchError(this.httpService.handleError("get")));
  }

  public getUserMaping(id){
    return this.httpService.get('mxrpp', `/user/${id}`)
      .pipe(
        catchError(this.httpService.handleError('get'))
      );
  }

}
