import { Component, OnInit, OnDestroy, Inject, ChangeDetectorRef, AfterViewChecked, HostBinding } from '@angular/core';
import { Subject } from 'rxjs';
import { GLOBALS, Global } from './core/global';
import { AuthenticationService } from '@quicksuite/commons-auth-plugin';
import { ProgressBarService } from './shared/services/progress-bar/progress-bar.service';
import { environment } from '../environments/environment';
import { takeUntil } from "rxjs/operators";
import { TenantService } from './shared/services/tenant/tenant.service';
import { Tenant } from './shared/constants/constants';
import { PartnerDocsUser } from './main/partner-docs/models/partner-docs-user.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
  public navbarisActive = false;
  constructor(@Inject(GLOBALS) public g: Global,
    public authService: AuthenticationService, private readonly spinnerService:
      ProgressBarService, private readonly cdr: ChangeDetectorRef, private readonly tenantService: TenantService) {
  }

  @HostBinding("class.theme-us") public usTheme: boolean;
  @HostBinding("class.theme-mx") public mxTheme: boolean;

  loading = false;

  ngOnInit(): void {
    this.enableThemes();
    this.authService.TryLogin();
    this.authService.getUserStream()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(u => {
        this.g.user = u;
        this.g.partnerDocsUser = new PartnerDocsUser(u);
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


  ngAfterViewChecked(): void {
    this.spinnerService.spinnerStatus.subscribe((val: boolean) => {
      if (val !== this.loading) {
        this.loading = val;
        this.cdr.detectChanges();
      }
    });
  }

  enableThemes() {
    this.usTheme = this.tenantService.getTenant() === Tenant.US;
    this.mxTheme = this.tenantService.getTenant() === Tenant.MX;
  }

  public get showNavigation(): boolean {
    return (!environment.useAuth || this.g.user && this.g.user.unique_name) ? true : false;
  }

}
