import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../services/storage/local-storage.service';

@Component({
  selector: 'app-impersonate',
  templateUrl: './impersonate.component.html',
  styleUrls: ['./impersonate.component.scss']
})
export class ImpersonateComponent implements OnInit, AfterViewChecked {

  impersonateData;
  exitImpersonateConfirm;
  dynamicStyle = false;

  constructor( private readonly localStorageService: LocalStorageService,
     private readonly router: Router) { }

  ngAfterViewChecked(): void {
    this.impersonateData =  this.localStorageService.get('impersonate');
  }


  ngOnInit(): void {
    setTimeout(() => {
      this.dynamicStyle = true;
    }, 3000);
  }

  exitImpersonate() {
    this.impersonateData = '';
    this.localStorageService.removeItem('impersonate');
    this.exitImpersonateConfirm = false;
    this.dynamicStyle = false;
    window.location.href = '/usrpphome';
  }


  confirmExitImpersonate(evt) {
    this.exitImpersonateConfirm = evt;
  }
}

