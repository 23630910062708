// IMPORTANT!!! PLEASE DO NOT ERASE ANY COMMENT STARTING WITH ##

import { Injectable, Inject } from '@angular/core';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';

declare const $: any;
declare interface Mroute {
  route: string;
  name: string;
  icon: string;
  target?: string;
}

@Injectable()
export class MenuRoutingService {
    constructor(@Inject(I18NEXT_SERVICE) private readonly i18NextService: ITranslationService  ) { }

    public get AllMenuItems(): Array<any> {
        const menuRoutes: Mroute[] = [
            { name: $localize`Dashboard`, icon: 'appkiticon icon-home-outline', route: 'dashboard' },
            { name: $localize`Profile`, icon: 'appkiticon icon-person-outline', route: 'profile' },
            { name: $localize`Clients`, icon: 'appkiticon icon-person-outline', route: 'clients' },
        { route: 'mxrpp', name: 'mxrpp', icon: 'appkiticon icon-grid-view-outline' },
// ##chunk: menu-routing.service.ts
        ];
        return menuRoutes;
    }
}
