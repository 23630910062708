<div *ngIf="impersonateData?.fullName">
<div class="impersonateView" [ngClass]="{'moveTop': dynamicStyle}" *ngIf="impersonateData?.fullName">
    Previewing as "<strong>{{impersonateData?.fullName}}</strong>" 
    <a (click)="confirmExitImpersonate(true)"> Exit</a>
</div>


<div class="confirmDisplays" *ngIf="exitImpersonateConfirm">
    <apk-modal [(display)]="exitImpersonateConfirm" >
      <apk-modal-header>
        Do you want to proceed?
      </apk-modal-header>
    
      <div>
        You are trying to exit impersonate profile of "<strong>{{impersonateData?.fullName}}</strong>" to your actual logged in profile in the My Connection portal. Do you want to proceed?
      </div>
    
      <apk-modal-action>
        <apk-button type="secondary" (clicked)="confirmExitImpersonate(false)">
          Cancel
        </apk-button>
        <apk-button type="primary" (clicked)="exitImpersonate()">
          Ok
        </apk-button>
      </apk-modal-action>
    </apk-modal>
    </div>
  </div>