import { InjectionToken, Injectable } from '@angular/core';
import { ApplicationUser} from '@quicksuite/commons-auth-plugin';
import { PartnerDocsUser } from '../main/partner-docs/models/partner-docs-user.model';

@Injectable()
export class Global {
    user: ApplicationUser = {};
    partnerDocsUser: PartnerDocsUser;
}

// Injection token GLOBALS of type Global
export const GLOBALS = new InjectionToken<Global>('GLOBALS');
