import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { datadogRum } from '@datadog/browser-rum';

try{
  if(environment.dataDog){
    datadogRum.init(environment.dataDog);
    window['DD_RUM'].init(environment.dataDog)
  }
}
catch(e){
  console.log(e,"dataDogError")
}


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
