export const primaryNavLinks = [
    {
        name: 'Communications',
        type: 'link',
        rbacPageName: 'view_communication',
        route: '/communications',
        order: 1,
    },
    {
        name: 'Benefits',
        type: 'link',
        rbacPageName: 'view_benefit',
        route: '/benefits',
        order: 2,
    },
    {
        name: 'Financial info',
        type: 'dropdown',
        rbacPageName: 'view_financial_full',
        order: 3,
        items: [
            {
                name: 'CFDI documents',
                route: 'financialinfo/cfdidocuments'
            },
            {
                name: 'XML documents',
                route: 'financialinfo/xmldocuments'
            },
            {
                name: 'Annual total income',
                route: 'financialinfo/annualtotalincome'
            },
            {
                name: 'Leasing car',
                route: 'financialinfo/leasingcar'
            }, {
                name: 'Monthly retirement details',
                route: 'financialinfo/monthlyretirementdetails'
            }, {
                name: 'PDP',
                route: 'financialinfo/pdp'
            }//,  {
               // name: 'COLA statements',
                //type:'link',
              //  route: 'https://sitepwcpartner.pensionlive.com'
            //}

        ]
    },
    {
        name: 'Tax',
        type: 'link',
        rbacPageName: 'view_tax',
        order: 4,
        route: '/tax',
    },
    {
        name: 'Activity report',
        type: 'link',
        rbacPageName: 'view_activity',
        route: '/activityreport',
        order: 5,
    },
    {
        name: 'Regulation',
        type: 'link',
        rbacPageName: 'view_regulation',
        route: '/regulation',
        order: 6,
    }
];

export const usrppPrimaryNavLinks = [
    {
        name: 'Life events',
        type: 'link',
        rbacPageName: 'view_life_events',
        route: '/lifeevents',
        order: 2,
    },
    {
        name: 'Personal details',
        type: 'link',
        rbacPageName: 'view_personal_details',
        route: '/personaldetails',
        order: 3,
    },
    {
        name: 'Partner taxation',
        type: 'link',
        rbacPageName: 'view_partner_taxation',
        order: 4,
        items: '',
        route: '/partnertaxation',
    },
    {
        name: 'Partner connectivity',
        type: 'link',
        rbacPageName: 'view_partner_connectivity',
        order: 5,
        items: '',
        route: '/partnerconnectivity'
    },
    {
        name: 'Financial information',
        type: 'link',
        rbacPageName: 'view_shortcuts',
        route: '/financialinformation',
        order: 6
    },
    {
        name: 'Obituaries',
        type: 'link',
        rbacPageName: 'view_obituaries',
        route: '/obituaries',
        order: 7
    },
    {
        name: 'Onboarding',
        type: 'link',
        rbacPageName: 'view_user_onboarding',
        route: '/userOnboarding',
        order: 8
    },
    {
        name: 'SPO',
        type: 'link',
        rbacPageName: 'view_spo',
        route: '',
        order: 9
    }
];

export const adminNavLinks = {
    name: 'Admin',
    type: 'dropdown',
    rbacPageName: 'admin',
    order: 9,
    items: [
        {
            name: 'User management',
            route: '/admin'
        },
        {
            name: 'Support email',
            route: '/supportMail'
        },
        {
            name: 'User mapping',
            route: '/admin/usermapping'
        }
    ]
};

const tableColumns = {
    cfdiManual: 'cfdiManual',
    created: 'created',
    xmlDocument: 'xmlDocument',
    document: 'document',
    employeeName: 'employeeName',
    employeeId: 'employeeId',
    comments: 'comments',
    dateUploaded: 'dateUploaded',
    vehicleType: 'vehicleType',
    model: 'model',
    serialNumber: 'serialNumber',
    totalLeasing: 'totalLeasing',
    deductible: 'deductible',
    accountName: 'accountName',
    emailedTo: 'emailedTo',
    WFExceed: 'WFExceed',
    WFTax: 'WFTax',
    WFTotal: 'WFTotal',
    itemNumber: 'itemNumber',
    category: 'category',
    sender: 'sender',
    date: 'date',
    time: 'time',
    status: 'status',
    comment: 'comment',
    firstName: 'firstname',
    lastName: 'lastname',
    ppid: 'ppid',
    email: 'email',
    employeeID1: 'employeeID',
    annualTotalIncome: 'annualTotalIncome',
    monthlyRetirementSpecs: 'monthlyRetirementSpecs',
    employeeIdReg: 'employeeIdReg',
    title: 'Title',
    dateCreated: 'Date created',
    createdBy: 'Requestor',
    fullName: 'Retired partner',
    rpGUID: 'GUID',
    reqStatus: 'Onboarding status',
    rpEmplID: 'Employee ID',
    rpEmailID: 'Personal email',
    createdAt: 'Created date',
    transactionDate: 'RPP effective date',
    createdTime: 'Created time',
    user: 'User',
    actionTaken: 'Action taken',
    description: 'Description',
    commentsView: 'Comments',
    partnerStatus: 'Partner status'
};

const tableValues = {
    name: 'name',
    created: 'created',
    partnerName: 'partnerName',
    employeeId: 'employeeId',
    comment: 'comment',
    comments: 'comments',
    dateUploaded: 'dateUploaded',
    vehicleType: 'vehicleType',
    model: 'model',
    serialNumber: 'serialNumber',
    totalLeasing: 'totalLeasing',
    deductible: 'deductible',
    accountName: 'accountName',
    emailID: 'emailID',
    wfExceed: 'wfExceed',
    wfTax: 'wfTax',
    wfTotal: 'wfTotal',
    itemNumber: 'itemNumber',
    category: 'category',
    creator: 'creator',
    date: 'time',
    status: 'status',
    time: "displaytime",
    firstName: 'firstName',
    lastName: 'lastName',
    ppid: 'ppid',
    email: 'emailID',
    employeeID1: 'employeeID',
    createdBy: 'createdBy',
    fullName: 'fullName',
    rpGUID: 'rpGUID',
    reqStatus: 'reqStatus',
    rpEmplID: 'rpEmplID',
    rpEmailID: 'rpEmailID',
    createdAt: 'createdAt',
    transactionDate: 'transactionDate',
    createdTime: 'createdAtTime',
    actionTaken: 'actionTaken',
    description: 'description',
    partnerStatus: 'isActive'
}

export const auditTrailColumns = [
    { value: tableValues.createdAt, name: tableColumns.createdAt, sortable: false, width: "50px" },
    { value: tableValues.createdTime, name: tableColumns.createdTime, sortable: false, width: "80px" },
    { value: tableValues.createdBy, name: tableColumns.user, sortable: false, width: "105px" },
    { value: tableValues.actionTaken, name: tableColumns.actionTaken, sortable: false, width: "145px" },
    { value: tableValues.description, name: tableColumns.description, sortable: false, width: "233px" },
];

export const commentColumns = [
    { value: tableValues.createdAt, name: tableColumns.createdAt, sortable: false, width: "100px" },
    { value: tableValues.createdTime, name: tableColumns.createdTime, sortable: false, width: "105px" },
    { value: tableValues.createdBy, name: tableColumns.user, sortable: false, width: "109px" },
    { value: tableValues.comment, name: tableColumns.commentsView, sortable: false, width: "512px" },
];

export const cfdiDocTableColumns = [
    { value: tableValues.name, name: tableColumns.cfdiManual, sortable: true },
    { value: tableValues.created, name: tableColumns.created, sortable: true, type: "date", width: '500px' }
];

export const taxStatementColumnsList = [
    { value: 'documentType', name: 'Document type', sortable: true },
    { value: 'created', name: 'Date issued', sortable: true},
    { value: 'year', name: 'Year', sortable: true},
];

export const shortcutsColumnsList = [
    { value: 'documentType', name: 'Document type', sortable: true },
    { value: 'created', name: 'Date issued', sortable: true},
    { value: 'year', name: 'Year', sortable: true}
];

export const commTaxationColumnName =
    { value: 'name', name: 'Title', sortable: true, router: 'link', type: 'click', color: 'orange', action: '' }

export const commTaxationColumnCreated =
    { value: 'created', name: 'Date created', sortable: true}

export const xmlDocumentsColumns = [
    { value: tableValues.name, name: tableColumns.xmlDocument, sortable: true },
    { value: tableValues.created, name: tableColumns.created, sortable: true, type: "date", width: '500px' }
];

export const regulationTableColumns = [
    { value: tableValues.name, name: tableColumns.document, sortable: true, isRoute: true },
    { value: tableValues.partnerName, name: tableColumns.employeeName, sortable: true },
    { value: tableValues.employeeId, name: tableColumns.employeeIdReg, sortable: true },
    { value: tableValues.created, name: tableColumns.dateUploaded, sortable: true, type: "date", }
];

export const userMapTableColumns = [
    { value: tableValues.firstName, name: tableColumns.firstName, sortable: true },
    { value: tableValues.lastName, name: tableColumns.lastName },
    { value: tableValues.employeeID1, name: tableColumns.employeeID1 },
    { value: tableValues.ppid, name: tableColumns.ppid },
    { value: tableValues.email, name: tableColumns.email }
];

export const onboardingListColumns = [
    { value: tableValues.createdBy, name: tableColumns.createdBy, sortable: true},
    { value: tableValues.fullName, name: tableColumns.fullName, sortable: true },
    { value: tableValues.rpGUID, name: tableColumns.rpGUID, sortable: true },
    { value: tableValues.reqStatus, name: tableColumns.reqStatus, sortable: true, width: "160px" },
    { value: tableValues.rpEmplID, name: tableColumns.rpEmplID, sortable: true, width: "120px" },
    { value: tableValues.rpEmailID, name: tableColumns.rpEmailID, sortable: true },
    { value: tableValues.createdAt, name: tableColumns.createdAt, sortable: true, width: "120px" },
    { value: tableValues.transactionDate, name: tableColumns.transactionDate, sortable: true, width: "130px" },
    { value: tableValues.partnerStatus, name: tableColumns.partnerStatus, sortable: true, width: "130px" }
];

export const leasingCarColumns = [
    { value: tableValues.employeeId, name: tableColumns.employeeId, sortable: true },
    { value: tableValues.vehicleType, name: tableColumns.vehicleType, sortable: true },
    { value: tableValues.model, name: tableColumns.model, sortable: true, width: "100px" },
    { value: tableValues.serialNumber, name: tableColumns.serialNumber, sortable: true },
    { value: tableValues.totalLeasing, name: tableColumns.totalLeasing, sortable: true },
    { value: tableValues.deductible, name: tableColumns.deductible, sortable: true, width: "130px" }
];

export const ContactSupportAdminColumns = [
    { value: tableValues.category, name: tableColumns.category, sortable: true, width: "201px" },
    { value: tableValues.creator, name: tableColumns.sender, sortable: true, width: "164px" },
    { value: tableValues.date, name: tableColumns.date, sortable: true, width: "118px" },
    { value: tableValues.time, name: tableColumns.time, sortable: true, idth: "150px" },
    { value: tableValues.status, name: tableColumns.status, sortable: true, },
    { value: tableValues.comment, name: tableColumns.comment, sortable: true, width: "219px" }
];

export const monthlyRetirementColumns = [
    { value: tableValues.name, name: tableColumns.monthlyRetirementSpecs, sortable: true },
    { value: tableValues.created, name: tableColumns.created, sortable: true, width: '500px', type: "date" }
];

const filterConstants = {
    employeeId: "Employee ID",
    vehicleType: "Vehicle type",
    model: "Model",
    serialNumber: "Serial number",
    deductible: "Deductible",
    emailedTo: "Emailed to",
    accountName: "Account name",
    wfExceed: "WFExceed",
    totalLeasing: "Total leasing",
    wfTotal: "WFTotal",
    wfTax: "WFTax",
    itemNumber: "Item number",
    recordNumber: "Record number",
    category: "Category",
    sender: "Sender",
    time: "Time received",
    date: "Date received",
    status: "Status",
    comment: "Comments"
}

export const searchptionsOnboarding = [
    { label: 'Partner', value: 'Partner' }
];

export const searchByOptionsData = [
    { label: 'Select', value: ' ' },
    { label: filterConstants.employeeId, value: 'employeeId' },
    { label: filterConstants.vehicleType, value: 'vehicleType' },
    { label: filterConstants.model, value: 'model' },
    { label: filterConstants.serialNumber, value: 'serialNumber' },
    { label: filterConstants.totalLeasing, value: 'totalLeasing' },
    { label: filterConstants.deductible, value: 'deductible' }
];

export const searchByOptionsContactData = [
    { label: 'Select', value: ' ' },
    { label: filterConstants.category, value: 'category' },
    { label: filterConstants.sender, value: 'creator' },
    { label: filterConstants.status, value: 'status' }
];

export const taxOptions = [
    { label: 'Select', value: 'select' },
    { label: 'Title', value: 'title' },
    { label: 'Date created', value: 'created' },
];

export const taxSatementOptions = [
    { label: 'Select', value: ' ' },
    { label: 'Date issued', value: 'Created' },
    { label: 'Year', value: 'Year' },
];

export const defaultTax = [
    { label: 'Select', value: ' ' },
];

export const defaultDoctype = [
    { label: 'All', value: ' ' },
];

export const ptrTaxOptions = [
    { label: 'Select', value: ' ' },
    { label: 'Document type', value: 'Document_x0020_Types' },
    { label: 'Date issued', value: 'Created' },
    { label: 'Year', value: 'Year' },
];

export const onboardingListSearch = [
    { label: 'Select', value: 'select' },
    { label: 'Requestor', value: 'createdBy' },
    { label: 'Retired partner', value: 'rpFirstName' },
    { label: 'GUID', value: 'rpGUID' },
    { label: 'Employee ID', value: 'rpEmplID' },
    { label: 'Personal email', value: 'rpEmailID' },
    { label: 'Created date', value: 'createdAt' },
    { label: 'RPP effective date', value: 'transactionDate' },
];

export const onboardingStatusList = [
    { label: 'All', value: 'All' },
    { label: 'Draft', value: 'Draft' },
    { label: 'Submitted', value: 'Submitted' },
    { label: 'Pending PeopleSoft', value: 'Pending PeopleSoft' },
    { label: 'Pending SPO', value: 'Pending SPO' },
    { label: 'Completed', value: 'Completed' }
];

export const partnerStatusList = [
    { label: 'All', value: 'All' },
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' }
];

export const obituariesOptions = [
    { label: 'Select', value: ' ' },
    { label: 'Title', value: 'FileLeafRef' },
    { label: 'Date issued', value: 'Created' },
];

export const annualtotalIncomeColumns = [
    { value: tableValues.name, name: tableColumns.annualTotalIncome, sortable: true },
    { value: tableValues.created, name: tableColumns.created, sortable: true, width: '500px', type: "date" }
];

export const documentTypes = {
    cfdi: 'cfdi',
    cfdixml: 'cfdixml',
    regulation: 'Regulation',
    totalincome: 'totalincome',
    monthlyretirement: 'monthlyretirement'
}

export const mail = { askQuestion: 'pa.pm.ret@us.pwc.com' }

export const userRoles = {
    adminUser: 'admin_user',
    adminUserUs: 'admin_user_us',
    externalUserUs: 'external_user_us',
    restrictedUserUs: 'restricted_user_us',
    requestor: 'requestor',
    TSO_GAM: 'TSO_GAM',
    onboardingReadonlyUs: 'onboarding_readonly_us'
}

export const taxCategory = {
    faq: 'faq',
    comm: 'commtax'
}
export const paginatorOptions = {
    showPagination: true,
    rows: 10,
    options: [10, 50, 100, 200],
}

export const sortFaqTax = {
    sortBy: 'FileLeafRef',
    sortDirection: 'asc'
}

export const sortCommTax = {
    sortBy: 'Created',
    sortDirection: 'desc',
    top: 30
}
export const viewTableAction = {
    type: 'view',
    icon: 'appkiticon icon-view-outline',
    router: '',
    action: '',
}

export const downloadTableAction = {
    type: 'download',
    icon: 'appkiticon icon-download-outline',
    router: '',
    action: '',
}

export const GOOGLE_CAPTCHA = {
    SITE_KEY: "6LcxLTIgAAAAAKTlIb3ZJPHN1M7_AjtfLhbmwzWU"
}

export const country = {
    united_States: "United States"
}

export const AppName = {
    US: 'US Retired Partner Portal',
    MX: 'Mexico Retired Partner Portal'
}

export const header = {
    name: 'My Connection'
}

export const personalInfoOptions = [
    { title: 'Home and Mailing Address', link: '' },
    { title: 'Phone Numbers', link: 'https://upasportalstage.nam.pwcinternal.com/psp/HRU92UAT/PARTNER/HRMS/c/PARTNERS.HR_PERSONAL_PHONE.GBL?Page=HR_PERSONAL_PHONE&Action=U&NoCrumbs=Y' },
    { title: 'EFT Bank Accounts', link: ' ' },
    { title: 'Email Address', link: ' ' },
    { title: 'Name', link: ' ' },
    { title: 'Marital Status', link: ' ' },
    { title: 'Spouse / Domestic Partner', link: ' ' },
    { title: 'Second Residence Address', link: ' ' },
    { title: 'Education', link: ' ' },
    { title: 'Languages', link: ' ' },
    { title: 'Beneficiary Information', link: ' ' },
    { title: 'Income/Employment Verification', link: ' ' }
];

export const myLifeEventOptions = [
    {
        head: "Marital Status / Domestic Partner",
        list: [
            { title: "Home and Mailing Address", link: " " },
            { title: "Phone Numbers", link: " " },
            { title: "EFT Bank Accounts", link: " " },
            { title: "Email Address", link: " " },
            { title: "Emergency Contact Information", link: " " },
            { title: "Change Benefits Coverage", link: " " },
            { title: "Life Insurance Coverage", link: " " },
            { title: "Marital Status", link: " " },
            { title: "Name", link: " " },
            { title: "Spouse / Domestic Partner", link: " " }
        ],
    },
    {
        head: "Spouse Job Change",
        list: [
            { title: "Change Medical Coverage", link: " " }
        ]
    },
    {
        head: "Child's Loss of Benefits",
        list: [
            { title: "Verify Dependent Benefits Coverage", link: " " },
            { title: "Change Life Insurance Coverage", link: "" }
        ]
    },
    {
        head: "Moving/Home Purchase",
        list: [
            { title: "Home and Mailing Address", link: " " },
            { title: "Phone Numbers", link: " " },
            { title: "Second Residence Address", link: " " },
            { title: "Email Address", link: " " },
            { title: "Income/Employment Verification", link: " " },
            { title: "Wintrust Mortgage Program", link: " " }
        ]
    },
    {
        head: "Death in Family",
        list: [
            { title: "Change Benefits Coverage", link: " " },
            { title: "Change Life Insurance Coverage", link: " " },
            { title: "Beneficiary Information", link: " " },
            { title: "Contact Partner Affairs", link: " " }
        ]
    },
    {
        head: "Turning Age 65",
        list: [
            { title: "Medicare", link: " " },
            { title: "Social Security", link: " " },
            { title: "HSA Account", link: " " },
            { title: "My Spouse Turns 65", link: " " },
        ]
    }
];

export const homeTiles = [
    {
        name: 'Life events', src: '../../../assets/images/img1.png', link: 'lifeevents',
        roles: ['external_user_us', 'restricted_user_us', 'admin_user_us']
    },
    {
        name: 'Personal details', src: '../../../assets/images/img2.png', link: '/personaldetails',
        roles: ['external_user_us', 'restricted_user_us', 'admin_user_us',]
    },
    {
        name: 'Partner taxation', src: '../../../assets/images/img3.png', link: 'partnertaxation',
        roles: ['external_user_us', 'restricted_user_us', 'admin_user_us',]
    },
    {
        name: 'Partner connectivity', src: '../../../assets/images/img4.jpg', link: 'partnerconnectivity',
        roles: ['external_user_us', 'admin_user_us']
    }
];

export const homeTilesMenu = [
    { name: 'Balance sheet', link: "balanceSheet" },
    { name: 'Financial benefits', link: 'pensionLive' },
    { name: 'Medical/insurance benefits', link: 'medInsuranceBenefits' }
];

export const historicalDocuments = ['Cy Redetermination Statement', 'Fy Redetermination Statement','Accrual Capital Statement']

export const staticFileName = {
    gobalHotal : "Global hotel discounts.xlsx",
    depositCapital: "Deposit capital final settlement statement"
}

export const staticText = {
    empIdConfirmMsg: 'This employee id already exist in another onboarding request, please confirm correct entry of the employee id before proceeding.',
    pendingPeopleSoft: 'Pending PeopleSoft',
    noRecorsSelected: 'No records are selected to complete',
    partialSuccess: 'Bulk request partially approved successfully',
    fullSuccess: 'Bulk request approved successfully',
    completed: 'Completed'
}

export const onboardMessages = {
    uploadSuccessMsg : 'File uploaded successfully. A detailed status report of bulk partner onboarding will be emailed shortly.',
    uploadFailureMsg : 'File Upload Failed.',
    fileValidationErrorMsg : "The file format is invalid. Please upload file with .xlsx extension only.",
    uploadFailureMandatoryField : "An incorrect file is uploaded. Please upload a valid template file."
}

export const excelOnboardTitle = ['First name *', 'Last name *', 'Middle initial', 'Organization *', 'Personal email *', 'RPP effective date *', 'Country *', 'Contact Number', 'Employee ID *', 'Is partner connectivity access required? *']



