// System Components
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from '@quicksuite/commons-auth-plugin';
import { LoginLayoutComponent } from './layout/login-layout/login-layout.component';
import { LandingLayoutComponent } from './layout/landing-layout/landing-layout.component';
import { LogOutComponent } from './main/logout/logout.component';
// Custom Components


const routes: Routes = [
    {
        path: 'login',
        // Pass guards into canActivateChild which will act as the main auth.
        component: LoginLayoutComponent
    },
    {
        path: 'logout',
        component: LogOutComponent,
    },
    {
        path: '',
        // Pass guards into canActivateChild which will act as the main auth.
        canActivateChild: [AuthGuard],
        component: LandingLayoutComponent,
        children: [
            { path: '', loadChildren: () => import('./main/main.module').then(m => m.MainModule) }
        ]
    },
    {
        path: 'error',
        children: [
            { path: '', loadChildren: () => import('./errors/errors.module').then(m => m.ErrorsModule) }
        ]
    },
    { path: '**', redirectTo: '' }
];

const config: ExtraOptions = {
    useHash: false,
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
