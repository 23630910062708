<apk-modal [(display)]="modalDisplay" (displayChange)="closeModal(sendNoticeForm)" class="mainContact">
    <apk-modal-header>
        {{'contact_support.title' | i18next}}
    </apk-modal-header>
    <form [formGroup]="sendForm" role="form" #sendNoticeForm>     
        <apk-dropdown formControlName="category" [options]="categories"  
            label="{{'contact_support.labels.category' | i18next}}"  class="custom-err-input">
        </apk-dropdown>   
       <mat-error *ngIf="categoryErr"><small>{{'contact_support.categoryErr.content' | i18next}}</small></mat-error>    
        <apk-input label="{{'contact_support.labels.subject' | i18next}}" placeholder="{{'contact_support.subjectPlaceHolder.content' | i18next}}" class="custom-err-input" 
            formControlName="subject" >
        </apk-input>   
        <mat-error *ngIf="subjectErr"><small>{{'contact_support.subjectErr.content' | i18next}}</small></mat-error>  

        <apk-textarea max-length=250 label="{{'contact_support.labels.comment' | i18next}}"   name="comment" style="margin-top:10px"
        placeholder="{{'contact_support.commentPlaceHolder.content' | i18next}}"
            formControlName="comment"></apk-textarea> 
     <mat-error *ngIf="commentsErr" class="commentsErr"><small>{{'contact_support.commentsErr.content' | i18next}}</small></mat-error> 
   
      <re-captcha class="captcha" formControlName="captcha" (resolved)="getToken($event)"></re-captcha>
      <mat-error *ngIf="captchaErr && sendForm.get('captcha').invalid"><small>{{'contact_support.commentsErr.content' | i18next}}</small></mat-error> 

    </form>
    <div>

    </div>
    <apk-modal-action>
        <apk-button type="secondary" (clicked)="cancelModal(sendNoticeForm)" >
            {{'contact_support.btns.cancel' | i18next}}
        </apk-button>
        <apk-button type="primary" (clicked)="onSend(sendNoticeForm)">
            {{'contact_support.btns.send' | i18next}}
        </apk-button>
    </apk-modal-action>
</apk-modal>


