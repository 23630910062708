import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

// Service
import { ConfirmationdialogComponent } from './components/confirmationdialog/confirmationdialog.component';
import { ApplicationHttpClient, applicationHttpClientCreator } from './services/interceptor/http.client';
import { ProgressBarService } from '../shared/services/progress-bar/progress-bar.service';
import { AuthenticationService } from '@quicksuite/commons-auth-plugin';

import { I18NextModule } from 'angular-i18next';
import { TenantService } from './services/tenant/tenant.service';
import { LocalStorageService } from './services/storage/local-storage.service';
import { TranslatebuttonComponent } from './components/translatebutton/translatebutton.component';
import { ContactSupportComponent } from './components/contact-support/contact-support.component';
import { QuestionnaireComponent } from './components/questionnaire/questionnaire.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomApkTableComponent } from './components/customapk-table/customapk-table.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {
  ApkButtonModule,
  ApkInputModule,
  ApkDropdownModule,
  ApkNotificationModule,
  ApkTooltipModule,
  ApkHeaderModule,
  ApkModalModule,
  ApkTextareaModule,
  ApkDatepickerModule,
  ApkCheckboxModule
} from '@ak4a/components';
import { CustomapkFilterComponent } from './components/customapk-filter/customapk-filter.component';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { ConfigurationService } from './services/tenant/configuration.service';
import { SharepointService } from './services/sharepoint/sharepoint';
import { ImpersonateComponent } from './components/impersonate/impersonate.component';

const windowCaptcha = window['webAppConfig'];
const RECAPTCHA_KEY = windowCaptcha?.googleRecaptchaSiteKey || ' ';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    ApkButtonModule,
    ApkModalModule,
    I18NextModule,
    ApkDropdownModule,
    ApkTextareaModule,
    ApkInputModule,
    FormsModule,
    ReactiveFormsModule,
    ApkNotificationModule,
    ApkTooltipModule,
    ApkHeaderModule,
    ApkModalModule,
    ApkDatepickerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ApkCheckboxModule
  ],
  declarations: [
    ConfirmationdialogComponent,
    TranslatebuttonComponent,
    ContactSupportComponent,
    QuestionnaireComponent,
    CustomApkTableComponent,
    CustomapkFilterComponent,
    ImpersonateComponent
  ],
  exports: [
    ConfirmationdialogComponent,
    TranslatebuttonComponent,
    ContactSupportComponent,
    QuestionnaireComponent,
    CustomApkTableComponent,
    CustomapkFilterComponent,
    ImpersonateComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [LocalStorageService, SharepointService,
    {
      provide: ApplicationHttpClient,
      useFactory: applicationHttpClientCreator,
      deps: [HttpClient, AuthenticationService, ProgressBarService, TenantService, ConfigurationService]
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: RECAPTCHA_KEY
      } as RecaptchaSettings
    }]

})
export class SharedModule { }
