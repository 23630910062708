import * as CryptoJS from 'crypto-js';
import { Injectable } from '@angular/core';
import * as SecureStorage from 'secure-web-storage';

const KEY = 'localStorageKey';
@Injectable({
  providedIn: 'root'
})
export class StorageService {

  public secureStorage = new SecureStorage(localStorage, {
    hash: function hash(key) {
      return key.toString();
    },
    encrypt: function encrypt(data) {
      data = CryptoJS.AES.encrypt(data, KEY);
      data = data.toString();
      return data;
    },
    decrypt: function decrypt(data) {
      data = CryptoJS.AES.decrypt(data, KEY);
      data = data.toString(CryptoJS.enc.Utf8);
      return data;
    }
  });

}