import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({providedIn : 'root'})

export class SessionManagementService {
    isSessionValid  = new BehaviorSubject(false);

    activityTimer;

    activateTimers(time) {
        clearTimeout(this.activityTimer);
        this.activityTimer = setTimeout(() => {
            this.isSessionValid.next(true);
        }, time);
    }
    resetTimers(time) {
        this.isSessionValid.next(false);
        clearTimeout(this.activityTimer);
        this.activateTimers(time);
    }

}
