import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { ApplicationHttpClient } from '../../shared/services/interceptor/http.client';
import { Observable, Subject, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContactSupportAdminService {
    createUpdateEntity = new Subject<any>();
  constructor(private readonly httpService: ApplicationHttpClient) { }

      public getContactSupportDetails(queryParams?): Observable<any> {
        const queyFromParams = queryParams ? Object.keys(queryParams).map((key) => `${key}=${queryParams[key]}`).join('&') : undefined;
        const getPath = queyFromParams && queyFromParams.length > 0 ? '/contact/getcontactSupport?' + queyFromParams: '/contact/getcontactSupport'
        return this.httpService.get('mxrpp', getPath)
          .pipe(
            catchError(this.httpService.handleError('get'))
          );
      }
      public postContactSupportDetails(formData): Observable<any> {
        return this.httpService.post('mxrpp', formData, '/contact/newcontactSupport')
        .pipe(
          map(res => this.createUpdateEntity.next(res)),
          catchError(e => throwError(e))
        );
      }
      public updateContactSupportDetails(formData): Observable<any> {
        return this.httpService.put('mxrpp', formData, `/contact/updatecontactSupport/${formData.id}`)
        .pipe(
          map(res => this.createUpdateEntity.next(res)),
          catchError(this.httpService.handleError(`update id=${formData.id}`))
        );
      }

}
