import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { AuthenticationService }  from '@quicksuite/commons-auth-plugin';
import { take } from 'rxjs/operators';
import { LocalStorageService } from '../../shared/services/storage/local-storage.service'
import { LogoutService } from '../../core/services/logout/logout.service';
import { TenantService } from '../../shared/services/tenant/tenant.service';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { Tenant } from '../../shared/constants/constants';



@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogOutComponent  implements OnInit {
  expiredView = true;
  footerContent: string;
  CommonFooterContent: string;
  showLoginLink: boolean = true;
  constructor(private readonly authService: AuthenticationService,
    private readonly router: Router, public activatedRoute: ActivatedRoute,
     private readonly logoutService: LogoutService,
     private readonly localStorageService: LocalStorageService,
     private readonly tenantService: TenantService,
     @Inject(I18NEXT_SERVICE) private readonly i18NextService: ITranslationService,
     ) {
      router.events.subscribe((val) => {
        if (val instanceof ActivationEnd) {
          this.dynamicFooter();
        }
      });
     }

  ngOnInit() {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      this.expiredView = params.expired;
    });
    this.showLoginLink = !this.tenantService.isPartnerDocs();
    this.authService
      .Logout()
      .then(() => {
        this.localStorageService.clearToken()
        console.log('logged out succesfully');
      })
      .catch((x) => {
        console.error('Logout failed ', x);
      });
      const token = this.localStorageService.get('auth_token');
      this.authService.Logout();
        /* istanbul ignore next */
      this.logoutService.expireToken(token?.id_token).subscribe(
        (data) => {
          this.logoutService.loggedOut();
          sessionStorage.clear();
          localStorage.clear();
        },
        (err) => {
          if (err.status !== 200) {
            // to do show error notification
            console.log(err);
          }
        }
      );
   }


  login() {
    this.authService.NavigateToLogin();
  }
  dynamicFooter(){
   if(this.tenantService.getTenant()=== Tenant.MX){
    this.footerContent = this.i18NextService.t(`footer.genericContent`);
    this.CommonFooterContent = this.i18NextService.t(`footer.content`);
   }else{
    this.CommonFooterContent = this.i18NextService.t(`footer.USCommonContent`);
   }
  }

  leftAlignFooter() {
    return this.tenantService.getTenant() === Tenant.US;
  }

}
