const createandDownloadFile = (fileObj) => {
    let base64Code = fileObj?.data?.split(",")[1];
    base64Code = base64Code ? base64Code : fileObj?.data;
    const ext = fileObj.fileName?.substring(
        fileObj.fileName?.lastIndexOf(".") + 1
    );
    const linkSource = `data:application/${ext};base64,${base64Code}`;
    const downloadLink = document.createElement("a");
    const fileName = fileObj.fileName;
    downloadLink.href = linkSource;
    downloadLink.target = '_blank';
    downloadLink.download = fileName;
    downloadLink.click();
}

const createandViewFile = (fileObj, target?) => {
    const binary = atob(fileObj?.data.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
    }
    // create the blob object with content-type "application/pdf"
    const blob = new Blob([view], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    if (target) {
        setTimeout(() => {
            window.open(url, target);
        })
    } else {
        setTimeout(() => {
            window.open(url, '_blank');
        })
    }
}

const translateColumnNames = (i18nservice, columns) => {
    return columns.map((item) => {
        return {
            ...item,
            name: i18nservice.t(`tableColumns.${item.name}`)
        }
    })
}

const filterDataSource = (data, filters) => {
    if (filters.name && filters.name.trim() !== '') {
        data = data.filter(el => el.name.indexOf(filters.name) >= 0);
    }
    if (filters.created && filters.created !== '') {
        data = data.filter(el => {
            const rowDate = new Date(el.created);
            const filterDate = new Date(filters.created);
            return rowDate.toDateString() === filterDate.toDateString();
        });
    }
    return data;
}

const downloadFile = (financialInfoService, row) => {
    const data = { fileName: row.name, employeeID: row.employeeId, docType: row.docType, impersonateEmplID: row.impersonateEmplID  }
    financialInfoService.downloadFile(data).subscribe((res) => {
        createandDownloadFile(res);
    })
}

const viewFile = (financialInfoService, row) => {
    const data = { fileName: row.name, employeeID: row.employeeId, fileType: row.fileType, docType: row.docType, impersonateEmplID: row.impersonateEmplID }
    financialInfoService.downloadFile(data).subscribe((res) => {
        createandViewFile(res);
    })
}

const formatSPOResponse = (data) => {
    return data?.map(item => {
        return {
            ...item,
            created: new Date(item.created)
        }
    });
}

const spPagination = {
    pageParams: {
        startItem: '',
        top: 100,
        sortBy:'created',
        sortDirection:'desc'
      }
}

const historicalPagination = {
    pageParams: {
        startItem: '',
        top: 30,
        sortBy:'created',
        sortDirection:'desc',
        impersonateEmplID: ''
      }
}

const taxstatementPagination = {
    pageParams: {
        startItem: '',
        top: 30,
        sortBy:'created',
        sortDirection:'desc',
        type:'tax',
        impersonateEmplID: ''
      }
}

const capitalPagination = {
    pageParams: {
        startItem: '',
        top: 1,
        sortBy:'created',
        sortDirection:'desc',
        impersonateEmplID: ''
      }
}

const obituariesPagination = {
    pageParams: {
        startItem: '',
        top: 30,
        sortBy:'created',
        sortDirection:'desc',
        type:'obituaries'
      }
}

export { createandDownloadFile, translateColumnNames, filterDataSource, downloadFile, viewFile, formatSPOResponse, spPagination, historicalPagination, capitalPagination, createandViewFile , taxstatementPagination, obituariesPagination};

