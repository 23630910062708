import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApplicationHttpClient } from '../../shared/services/interceptor/http.client';

@Injectable()
export class FinancialInfoService {

  constructor(private readonly httpService: ApplicationHttpClient) { }

  public getDocuments(type, queryParams?): Observable<any> {
    const doctypeParam = `documentType=${type}`;
    const genObj = queryParams ? Object.keys(queryParams).map((key) => `${key}=${queryParams[key]}`).join('&') : '';
    const queyFromParams = queryParams ? `&${genObj}`  : '';
    return this.httpService.get('mxrpp', `/financialInfo/getdocuments?${doctypeParam}${queyFromParams}`)
      .pipe(
        catchError(this.httpService.handleError('get'))
      );
  }

  public getTaxationDocuments(type,queryParams?): Observable<any> {
    const doctypeParam = `documentType=${type}`;
    const genObj = queryParams ? Object.keys(queryParams).map((key) => `${key}=${queryParams[key]}`).join('&') : '';
    const queyFromParams = queryParams ? `&${genObj}`  : '';
    return this.httpService.get('mxrpp',  `/financialInfo/getTaxationDocuments?${doctypeParam}${queyFromParams}`)
      .pipe(
        catchError(this.httpService.handleError('get'))
      );
  }

  public getObiturayDocuments(type,queryParams?): Observable<any> {
    const doctypeParam = `documentType=${type}`;
    const genObj = queryParams ? Object.keys(queryParams).map((key) => `${key}=${queryParams[key]}`).join('&') : '';
    const queyFromParams = queryParams ? `&${genObj}`  : '';
    return this.httpService.get('mxrpp',  `/financialInfo/getObiturayDocuments?${doctypeParam}${queyFromParams}`)
      .pipe(
        catchError(this.httpService.handleError('get'))
      );
  }

  public getLeasingCarList(queryParams?): Observable<any> {
    const queyFromParams = queryParams ? Object.keys(queryParams).map((key) => `${key}=${queryParams[key]}`).join('&') : undefined;
    const getPath = queyFromParams && queyFromParams.length > 0 ? '/financialinfo/getleasingcarlist?' + queyFromParams
      : '/financialinfo/getleasingcarlist'
    return this.httpService.get('mxrpp', getPath)
      .pipe(
        catchError(this.httpService.handleError('get'))
      );
  }

  public getStateElectionDocuments(): Observable<any> {
    return this.httpService.get('mxrpp',  `/financialInfo/getStateElectionDocuments`)
      .pipe(
        catchError(this.httpService.handleError('get'))
      );
  }

  public downloadFile(data): Observable<any> {
    return this.httpService.post('mxrpp', data, '/download')
      .pipe(
        catchError(this.httpService.handleError('downloadFile'))
      );
  }

  public addLeasingCar(data): Observable<any> {
    return this.httpService.post('mxrpp', data, '/financialinfo/addleasingcar')
      .pipe(
        catchError(this.httpService.handleError('post'))
      );
  }

  public getEmpId(param): Observable<any> {
    return this.httpService.get('mxrpp', `/user/${param}`)
      .pipe(
        catchError(this.httpService.handleError('get'))
      );
  }

  public sendNotices(): Observable<any> {
    return this.httpService.post('mxrpp', '', '/sendnotices')
      .pipe(
        catchError(this.httpService.handleError('sendNotices'))
      );
  }

}
