import { ApplicationUser } from "@quicksuite/commons-auth-plugin";

// QS Application user with additional openAM claim fields
export interface IExtendedApplicationUser extends ApplicationUser {
	preferredMail?: string;
	pwcGlobalGradeName?: string;
	PwCPPI?: string;
	employeeNumber?: string;
	pwcWorkerID?: string;
}

export class PartnerDocsUser {
	staffLevel: string;
	guid: string;
	ppid: string;
	employeeId: string;
	firstName: string;
	lastName: string;
	name?: string;
	email?: string;

	constructor(user: IExtendedApplicationUser) {
		this.firstName = user.given_name;
		this.lastName = user.family_name;
		this.name = user.unique_name;
		this.email = user.preferredMail;
		this.guid = user.claims.pwcguid;
		this.staffLevel = user.pwcGlobalGradeName ?? '';
		this.ppid = user.PwCPPI ?? 'ppid'
		this.employeeId = user.pwcWorkerID || user.employeeNumber || 'employeeId';
	}
}