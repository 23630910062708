export enum Tenant {
    US = "US",
    MX = "MX"
}

export enum Languages {
    en = "en",
    es = "es"
}

export const PARTNER_DOCS_HOSTNAMES = [
    'webapp-bsky-partnerdocs.dev.qd.pwcinternal.com',
    'webapp-bsky-partnerdocs.tst.qd.pwcinternal.com',
    'webapp-bsky-partnerdocs.stg.qd.pwcinternal.com',
    'partnerdocuments-next.pwcinternal.com',
    'partnerdocuments-last.pwcinternal.com',
    'partnerdocuments.pwcinternal.com',
    'partnerdocuments.east.pwcinternal.com',
    'partnerdocuments.west.pwcinternal.com',
    'partnerdocs.dev.pwcinternal.com',
    'partnerdocs.east.dev.pwcinternal.com',
    'partnerdocs.west.dev.pwcinternal.com',
    'partnerdocs.tst.pwcinternal.com',
    'partnerdocs.east.tst.pwcinternal.com',
    'partnerdocs.west.tst.pwcinternal.com',
    'partnerdocs.stg.pwcinternal.com',
    'partnerdocs.east.stg.pwcinternal.com',
    'partnerdocs.west.stg.pwcinternal.com'
    //'localhost' // uncomment for partner docs local development
];

export const HOSTNAME_TENANT_MAP = [
    {
        hostnames: [
            'webapp-bsky-mxrpp.dev.qd.pwcinternal.com',
            'webapp-bsky-mxrpp.tst.qd.pwcinternal.com',
            'retiredpartner.stg.mx.pwc.com',
            'retiredpartner.mx.pwc.com',
            'retiredpartner.east.mx.pwc.com',
            'retiredpartner.west.mx.pwc.com',
            'retiredpartner.next.mx.pwc.com',
            'retiredpartner.last.mx.pwc.com',
            'mxrpp.dev.pwcinternal.com',
            'mxrpp.east.dev.pwcinternal.com',
            'mxrpp.west.dev.pwcinternal.com',
            'mxrpp.tst.pwcinternal.com',
            'mxrpp.east.tst.pwcinternal.com',
            'mxrpp.west.tst.pwcinternal.com',
            'retiredpartner.ngc.stg.mx.pwc.com',
            'retiredpartner.east.ngc.stg.mx.pwc.com',
            'retiredpartner.west.ngc.stg.mx.pwc.com'
        ],
        tenantId: 'MX',
        lang: ['es', 'en']
    },
    {
        hostnames: [
            'webapp-bsky-usrpp.dev.qd.pwcinternal.com',
            'webapp-bsky-usrpp.tst.qd.pwcinternal.com',
            'webapp-bsky-usrpp.stg.qd.pwcinternal.com',
            'retiredpartner.stg.us.pwc.com',
            'myConnection.pwcinternal.com',
            'myConnection.east.pwcinternal.com',
            'myConnection.west.pwcinternal.com',
            'myconnection.pwcinternal.com',
            'myconnection.east.pwcinternal.com',
            'myconnection.west.pwcinternal.com',
            'orchestrator-myConnection.pwcinternal.com',
            'myConnection-next.pwcinternal.com',
            'myConnection-last.pwcinternal.com',
            'usrpp.dev.pwcinternal.com',
            'usrpp.east.dev.pwcinternal.com',
            'usrpp.west.dev.pwcinternal.com',
            'usrpp.tst.pwcinternal.com',
            'usrpp.east.tst.pwcinternal.com',
            'usrpp.west.tst.pwcinternal.com',
            'usrpp.stg.pwcinternal.com',
            'usrpp.east.stg.pwcinternal.com',
            'usrpp.west.stg.pwcinternal.com',
            'localhost'
        ],
        tenantId: 'US',
        lang: ['en']
    },
    {
        hostnames: [...PARTNER_DOCS_HOSTNAMES],
        tenantId: 'PD',
        lang: ['en']
    }
];

export const RES_MSG = {
    POST_QTN_SUCCESS: 'Your question was sent successfully.',
    POST_QTN_FAILED: 'Your question was not sent.',
    GET_QTN_FAILED: 'An error occurred. Try again.',
    GET_QTN_NODATA: 'No subcategory list for selected category',
    CATEGORY_NODATA: 'No category list to load'
};

export const questionCategoryOptions = [
    {label: 'Select', value: ' '}
];

export const INIT_CATEGORY = 'init_config_category';

export const STATIC_TEXT = {
    noteContent: 'Note: If you have technical issues or questions related to My Connection, please call PwC Help at 1-877-PWC-HELP (1-877-792-4357). Select option 2 for Technology Support and then option 9 to reach a My Connection Help Desk resource.',
    commonDocumentsUS: 'Common Documents US',
    COMMON: 'COMMON',
    LNTOSPO: 'LNTOSPO'
}
