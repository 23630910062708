import { Injectable } from "@angular/core";
import { ApplicationHttpClient } from '../interceptor/http.client';
import { createandViewFile, createandDownloadFile } from "../../../utils/helper";
import { Observable, Subject, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})

export class SharepointService {
  updateEntity = new Subject<any>();
  constructor(
    private readonly httpService: ApplicationHttpClient
  ) { }

  downloadCommonFile(filename) {
    const data = { fileName: filename, employeeId: '', fileType: 'COMMON' }
    const fileExtn = filename?.split(".")[1];
    this.httpService.post('mxrpp', data, '/staticfiledownload').subscribe((res) => {
         if(fileExtn && (fileExtn?.toLowerCase() == "xlsx" || fileExtn?.toLowerCase() == "xlsm" )) {
        createandDownloadFile(res)
      }else {
        createandViewFile(res);
      }
    });
  }

  postQuestionnaire(formData): Observable<any> {
    return this.httpService.post('mxrpp', formData, '/postQuestionnaire')
    .pipe(
      catchError(this.httpService.handleError('post'))
    );
  }

  getQuestionnaire(params): Observable<any> {
      const queyFromParams = params ? Object.keys(params).map((key) => `${key}=${params[key]}`).join('&') : '';
      const getPath = queyFromParams?.length ? `/getQuestionnaire?${queyFromParams}`  : '/getQuestionnaire'
      return this.httpService.get('mxrpp', getPath)
        .pipe(
          catchError(this.httpService.handleError('get'))
        );
  }

  spoFileView(data) {
    return this.httpService.post('mxrpp', data, '/staticfiledownload')
    .pipe(catchError(e => throwError(e)))
  }

}