import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApplicationHttpClient } from '../../shared/services/interceptor/http.client';

@Injectable()
export class CommunicationService {

  constructor(private readonly httpService: ApplicationHttpClient) { }

  public downloadFile(data): Observable<any> {
    return this.httpService.post('mxrpp', data, '/staticfiledownload')
      .pipe(
        catchError(this.httpService.handleError('downloadFile'))
      );
  }
}
