import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GLOBALS, Global } from './global';
import { environment } from '../../environments/environment';
import { MenuRoutingService } from './services/menu-routing.service';
import { AngularAuthModule,OAUTH_CONFIG} from '@quicksuite/commons-auth-plugin';
import { LogoutService } from './services/logout/logout.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AngularAuthModule
  ],
  declarations: [

  ],
  exports: [],
  providers: [
    MenuRoutingService,
    // INCLUDE THIS IF YOU ARE CREATING AN INJECTION TOKEN (shown below)
    { provide: GLOBALS, multi: false, useClass: Global },
  LogoutService,
    {
      provide: OAUTH_CONFIG,
      useFactory: () => {
        return {
          discoveryUrl: environment.discoveryUrl,
          redirect: window.location.href,
          clientId: environment.clientId,
          timeToRefresh: 300,
          enableLogging: !environment.production,
          useAuth: environment.useAuth,
          scope: environment.scope,
          authCode: environment.authCode,
          authFlow: true, //toggles use of oidc client shim with authorization flow and open am
          autoRefresh: true, //silent renew
          serviceDomain: environment.serviceDomain,
          useRefreshToken : true, // as of 8/13/2021, OpenAM now returns refresh token and rotates it per access_token request
          googleRecaptchaSiteKey: environment.googleRecaptchaSiteKey,
          UPAS_URL: environment.UPAS_URL,
          SPO_BASE_URL: environment.SPO_BASE_URL
        };
      }
    }
  ]
})
export class CoreModule { }
