import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from '@quicksuite/commons-auth-plugin';

import { ProgressBarService } from '../progress-bar/progress-bar.service';
import { environment } from '../../../../environments/environment';
import { v4 as uuid } from 'uuid';
import { catchError, finalize } from 'rxjs/operators';
import { TenantService } from '../tenant/tenant.service';

export interface IRequestOptions {
  headers?: HttpHeaders;
  observe?: 'body';
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any;
}

export function applicationHttpClientCreator(http: HttpClient,
  authService: AuthenticationService, spinnerService: ProgressBarService, tenantService: TenantService) {
  return new ApplicationHttpClient(http, authService, spinnerService, tenantService);
}

@Injectable()
export class ApplicationHttpClient {
  /* istanbul ignore next */
  public pendingRequests = 0;
  public showLoading = false;

  // Extending the HttpClient through the Angular DI.
  public constructor(public http: HttpClient, private readonly authService: AuthenticationService,
    private readonly spinnerService: ProgressBarService, private readonly tenantService: TenantService) {
  }

  public getWithoutMethodName(url: string, param?: string, options?: IRequestOptions): Observable<any> {
    return this.intercept(this.http.get(environment.serviceDomain + url + param, this.requestOptions(options)));
  }
  /**
   * GET request
   * @param {string} endPoint it doesn't need / in front of the end point
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public get(url: string, param?: string, options?: IRequestOptions): Observable<any> {

    return this.intercept(this.http.get(this.getURLFromMethodName(url, param), this.requestOptions(options)));
  }

  /**
   * POST request
   * @param {string} endPoint end point of the api
   * @param {Object} params body of the request.
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public post(url: string, body: any, param?: string, options?: IRequestOptions): Observable<any> {
    return this.intercept(this.http.post(this.getURLFromMethodName(url, param), body, this.requestOptions(options)));
  }

  /**
   * PUT request
   * @param {string} endPoint end point of the api
   * @param {Object} params body of the request.
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public put(url: string, body: string, param?: string, options?: IRequestOptions): Observable<any> {

    return this.intercept(this.http.put(this.getURLFromMethodName(url, param), body, this.requestOptions(options)));
  }

  /**
   * DELETE request
   * @param {string} endPoint end point of the api
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public delete(url: string, param?: string, options?: IRequestOptions): Observable<any> {

    return this.intercept(this.http.delete(this.getURLFromMethodName(url, param), this.requestOptions(options)));

  }

  intercept(observable: Observable<any>): Observable<any> {
    this.spinnerService.showSpinner(true);
    this.pendingRequests++;
    return observable.pipe(
      catchError(e => {
        this.pendingRequests = 0;
        this.spinnerService.showSpinner(false);
        return throwError(e)
      }),
      finalize(() => {
        this.pendingRequests--;
        this.spinnerService.showSpinner(false);
      })
    )
  }

  /**
  * Get the url from method name
  * @param methodName name of the methodname to retrieve url
  */
  private getURLFromMethodName(methodName: string, params?: string, requestType?: string): string {
    let url = '';
    const hostname: string = environment.serviceDomain;
    if (methodName) {
      methodName = methodName.toLowerCase();
      const serviceUrls = environment.serviceEndPoints;
      if (serviceUrls && serviceUrls[methodName]) {
        url = hostname + serviceUrls[methodName];
      }
      if (url && params) {
        url = url + params;
      }
    }
    return url;
  }

  private requestOptions(options?: IRequestOptions): IRequestOptions {

    if (!options) {
      options = {};
    }

    if (!options.headers) {
      // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
      options.headers = new HttpHeaders({
        'Accept': 'application/json',
        'X-Request-Id': uuid(),
        'Authorization': this.authService.AuthHeaderValue
      });
    }
    options.headers = this.tenantService.addTenantToHeaders(options.headers);
    return options;
  }

  /**
* Handle Http operation that failed.
* Let the app continue.
* @param operation - name of the operation that failed
* @param result - optional value to return as the observable result
*/
  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      /* istanbul ignore next */
      // send the error to remote logging infrastructure
      console.error(error); // log to console instead

      throw throwError(new Error(error));
    };
  }
}
