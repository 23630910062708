import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularAuthModule } from '@quicksuite/commons-auth-plugin';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { HttpClientModule } from '@angular/common/http';
import { ApplicationHttpClient } from './shared/services/interceptor/http.client';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProgressBarService } from './shared/services/progress-bar/progress-bar.service';
import { ApkHeaderModule, ApkFooterModule, ApkInputModule, ApkButtonModule, ApkCheckboxModule, ApkInputMaskModule, ApkNotificationModule } from '@ak4a/components';
import { LandingLayoutComponent } from './layout/landing-layout/landing-layout.component';
import { LoginLayoutComponent } from './layout/login-layout/login-layout.component';
import { FlexLayoutModule } from '@angular/flex-layout';

import { I18NextModule } from 'angular-i18next';
import { I18N_PROVIDERS } from './utils/i18n';
import { TenantService } from './shared/services/tenant/tenant.service';
import { RbacConfiguration, RbacModule} from '@rco/ng-rbac-1.0.0';
import { environment } from '../environments/environment';
import { SessionManagementModule } from './core/session-management/session-mangement.module';
import { SharedModule } from './shared/shared.module';
import { CommunicationService } from './main/communication/communication.service';
import { FinancialInfoService } from './main/financial-info/financial-info.service';

const rbacConfig: RbacConfiguration = {
  userPermissionsServiceConfig: {
    serviceDomain: environment.serviceDomain,
    getUserPermissionsEndPoint: 'userrolepermissions',
  },
  authMode: 'any'
};

@NgModule({
  declarations: [
    AppComponent,
    LandingLayoutComponent,
    LoginLayoutComponent
  ],
  imports: [
    SessionManagementModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    AppRoutingModule,
    AngularAuthModule,
    RbacModule.forRoot(rbacConfig),
    I18NextModule.forRoot({
      // errorHandlingStrategy: StrictErrorHandlingStrategy
    }),
    MatProgressSpinnerModule,
    ApkHeaderModule,
    ApkFooterModule,
    FlexLayoutModule,
    ApkInputModule,
    ApkButtonModule,
    ApkCheckboxModule,
    ApkInputMaskModule,
    ApkNotificationModule,
    SharedModule
  ],
  exports: [
    //...I18N_MODULE
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: 'authFlow', useValue: false },
    CommunicationService, //will default to true in future release
    FinancialInfoService,
    ApplicationHttpClient,
    ProgressBarService,
    TenantService,
    I18NextModule,
    I18N_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }



